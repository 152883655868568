export interface PopularSearchType {
  label: string
  value: string
}

const popularProviders: PopularSearchType[] = [
  {
    label: 'English Language School',
    value: 'results?subtype=English+Language+School',
  },
  {
    label: 'Private Training Establishment',
    value: 'results?subtype=Private+Training+Establishment',
  },
  {
    label: 'School',
    value:
      'results?subtype=Primary+School&subtype=Intermediate+School&subtype=Secondary+School&countries=New+Zealand',
  },
  {
    label: 'University',
    value: 'results?subtype=University',
  },
  {
    label: 'Institute of Technology or Polytechnic',
    value: 'results?subtype=Institute+of+Technology+or+Polytechnic',
  },
]

const popularCourses: PopularSearchType[] = [
  {
    label: 'Applied and Pure Sciences',
    value: 'results?areasOfStudy=Applied+and+Pure+Sciences',
  },
  {
    label: 'Creative Arts and Design',
    value: 'results?areasOfStudy=Creative+Arts+and+Design',
  },
  {
    label: 'Business and Administrative Studies',
    value: 'results?areasOfStudy=Business+and+Administrative+Studies',
  },
  {
    label: 'Engineering and Technology',
    value: 'results?areasOfStudy=Engineering+and+Technology',
  },
  {
    label: 'Computer and Mathematical Science',
    value: 'results?areasOfStudy=Computer+and+Mathematical+Science',
  },
  {
    label: 'Health and Medicine',
    value: 'results?areasOfStudy=Health+and+Medicine',
  },
]

export { popularCourses, popularProviders }
