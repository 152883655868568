import React from 'react'
import { connectHits, HitsProvided } from 'react-instantsearch-core'
import { createUtagStartEnquiryEvent, INVENTORY_LABELS } from 'tracking'
import { DetailItem, FinderResultItem, FORM_COMPONENTS } from 'ui'
import { SignUpType } from '../../../components/SignUpModal'
import { EnquiryFormConnectProps } from '../../../connectors'
import { ROUTES } from '../../../constants'
import { validateScholarshipDetailProps } from '../../../templates/ScholarshipDetailPage/helpers'
import { FinderResultItemEnquiryButton } from '../FinderResultItemButtons'

interface ScholarshipHit {
  title: string
  providerName: string
  eligibilityCriteria: string
  applicationInstructions: string
  id: string
  displayValue: string
  numberOfAwards: string
  providerLogoUrl: string
}

type ComponentProps = HitsProvided<ScholarshipHit> & {
  openEnquiryForm: ({
    providerName,
    providerSubtype,
    scholarshipName,
    formId,
    formComponent,
  }: EnquiryFormConnectProps) => void
  idsOfEnquiredInventoryItems: string[]
}

const Component: React.FunctionComponent<ComponentProps> = ({
  hits,
  openEnquiryForm,
  idsOfEnquiredInventoryItems,
}) => {
  const createDetailItems = (hit: ScholarshipHit): DetailItem[] | [] => {
    const { numberOfAwards, displayValue } = hit

    return [
      // ENZ-1260 - Hidden until it is ready to be re-added at a later date
      // ...(displayValue ? [{ label: 'Value', value: displayValue }] : []),
      ...(numberOfAwards ? [{ label: 'Number of awards', value: numberOfAwards }] : []),
    ]
  }

  return (
    <span>
      {hits.map((hit: ScholarshipHit) => {
        const validScholarshipHitProps = validateScholarshipDetailProps(hit)
        const detailItems = createDetailItems(hit)
        return (
          <FinderResultItem key={hit.id}>
            {validScholarshipHitProps.providerLogoUrl && (
              <FinderResultItem.Logo
                provider={`${validScholarshipHitProps.providerName}`}
                logoUrl={validScholarshipHitProps.providerLogoUrl}
              />
            )}
            <FinderResultItem.MainContent>
              <FinderResultItem.Title
                title={validScholarshipHitProps.title}
                subTitle={validScholarshipHitProps.providerName}
                link={`${ROUTES.SCHOLARSHIP.DETAIL}/${validScholarshipHitProps.id}`}
              />
              <FinderResultItem.Description
                intro={validScholarshipHitProps.eligibilityCriteriaHtmlString}
              />
              {detailItems && detailItems.length > 0 && (
                <FinderResultItem.DetailItems detailItems={detailItems} />
              )}
            </FinderResultItem.MainContent>
            <FinderResultItem.Buttons>
              <FinderResultItem.DetailsButton
                link={`${ROUTES.SCHOLARSHIP.DETAIL}/${validScholarshipHitProps.id}`}
                detailCta={{
                  label: 'See details',
                }}
              />
              <FinderResultItemEnquiryButton
                isEnquired={idsOfEnquiredInventoryItems.includes(hit.id)}
                inventoryId={validScholarshipHitProps.id}
                openEnquiryForm={() => {
                  createUtagStartEnquiryEvent({
                    pageName: INVENTORY_LABELS.SCHOLARSHIP.PAGE_NAME,
                    inventoryId: validScholarshipHitProps.id,
                    inventoryObjectType: INVENTORY_LABELS.SCHOLARSHIP.INVENTORY_OBJECT_TYPE,
                    levelOfStudy: '',
                    areaOfStudyArray: [],
                    subjectOfStudyArray: [],
                    deliveryModeArray: [],
                    courseName: '',
                    providerName: validScholarshipHitProps.providerName,
                    providerType: validScholarshipHitProps.providerType,
                    providerSubtype: validScholarshipHitProps.providerSubtypeList,
                    scholarshipName: validScholarshipHitProps.title,
                    campusRegionArray: [],
                    linkLabel: 'Enquire',
                  })
                  openEnquiryForm({
                    scholarshipName: validScholarshipHitProps.title,
                    providerName: validScholarshipHitProps.providerName,
                    providerSubtype: validScholarshipHitProps.providerSubtypeList,
                    formId: validScholarshipHitProps.id,
                    formComponent: FORM_COMPONENTS.SCHOLARSHIP_ENQUIRY,
                    loginType: SignUpType.Enquiry,
                    onClose: () => false,
                  })
                }}
              />
            </FinderResultItem.Buttons>
          </FinderResultItem>
        )
      })}
    </span>
  )
}

const ScholarshipHits = connectHits<ComponentProps, ScholarshipHit>(Component)

export default ScholarshipHits
