import { format, parseISO } from 'date-fns'
import React from 'react'
import { connectHits, HitsProvided } from 'react-instantsearch-core'
import { createTagList, createUtagStartEnquiryEvent, INVENTORY_LABELS } from 'tracking'
import { DetailItem, FinderResultItem, FORM_COMPONENTS, StudyOptionItemType } from 'ui'
import { SignUpType } from '../../../components/SignUpModal'
import { EnquiryFormConnectProps } from '../../../connectors'
import { ROUTES } from '../../../constants'
import {
  FinderResultItemEnquiryButton,
  FinderResultItemSaveButton,
} from '../FinderResultItemButtons'

interface Intakes {
  startDate?: string
  duration?: string
}
interface TaxonomyField {
  name: string
  tag: string
}

interface CourseHit {
  name: string
  providerName: string
  details: string
  id: string
  levelOfStudy: {
    name: string
    tag: string
  }
  duration: string
  providerLogoUrl: string
  intakes: Intakes[]
  regions: TaxonomyField[] | []
  providerSubtypeList: TaxonomyField[] | []
  providerType: TaxonomyField
  areasOfStudy: TaxonomyField[] | []
  deliveryModes: TaxonomyField[] | []
  subjectsOfStudy: TaxonomyField[] | []
}

type ComponentProps = HitsProvided<CourseHit> & {
  openEnquiryForm: ({
    courseName,
    providerName,
    providerSubtype,
    loginType,
  }: EnquiryFormConnectProps) => void
  saveInventoryItem: ({
    courseName,
    providerName,
    loginType,
  }: EnquiryFormConnectProps & { itemType: StudyOptionItemType }) => void
  idsOfEnquiredInventoryItems: string[]
}

const Component: React.FunctionComponent<ComponentProps> = ({
  hits,
  openEnquiryForm,
  saveInventoryItem,
  idsOfEnquiredInventoryItems,
}) => {
  const createDetailItems = (hit: CourseHit): DetailItem[] | [] => {
    const { levelOfStudy, intakes = [] } = hit
    const { name = '' } = levelOfStudy || {}
    const { duration = '', startDate = '' } = intakes[0] || {}
    const formattedStartDate = Boolean(startDate) && format(parseISO(startDate), 'do MMMM yyyy')

    return [
      ...(name ? [{ label: 'Level of study', value: name }] : []),
      ...(duration ? [{ label: 'Duration', value: duration }] : []),
      ...(formattedStartDate
        ? [
            {
              label: 'Next start date',
              value: `Expected ${formattedStartDate}`,
            },
          ]
        : []),
    ]
  }

  return (
    <span>
      {hits.map((hit: CourseHit) => {
        const detailItems = createDetailItems(hit)
        return (
          <FinderResultItem key={hit.id}>
            {hit.providerLogoUrl && (
              <FinderResultItem.Logo provider={hit.providerName} logoUrl={hit.providerLogoUrl} />
            )}
            <FinderResultItem.MainContent>
              <FinderResultItem.Title
                title={hit.name}
                subTitle={hit.providerName}
                link={`${ROUTES.COURSE.DETAIL}/${hit.id}`}
              />
              <FinderResultItem.Description intro={hit.details} />
              {detailItems && detailItems.length > 0 && (
                <FinderResultItem.DetailItems detailItems={detailItems} />
              )}
            </FinderResultItem.MainContent>
            <FinderResultItem.Buttons>
              <FinderResultItem.DetailsButton
                link={`${ROUTES.COURSE.DETAIL}/${hit.id}`}
                detailCta={{
                  label: 'See course details',
                }}
              />
              <FinderResultItemEnquiryButton
                isEnquired={idsOfEnquiredInventoryItems.includes(hit.id)}
                inventoryId={hit.id}
                openEnquiryForm={() => {
                  createUtagStartEnquiryEvent({
                    pageName: INVENTORY_LABELS.COURSE.PAGE_NAME,
                    inventoryId: hit?.id ?? '',
                    inventoryObjectType: INVENTORY_LABELS.COURSE.INVENTORY_OBJECT_TYPE,
                    levelOfStudy: hit?.levelOfStudy?.tag ?? '',
                    areaOfStudyArray: createTagList(hit?.areasOfStudy),
                    subjectOfStudyArray: createTagList(hit?.subjectsOfStudy),
                    deliveryModeArray: createTagList(hit?.deliveryModes),
                    courseName: hit?.name ?? '',
                    providerName: hit?.providerName ?? '',
                    providerType: hit?.providerType?.tag ?? '',
                    providerSubtype: createTagList(hit?.providerSubtypeList),
                    campusRegionArray: createTagList(hit?.regions),
                    linkLabel: 'Enquire',
                  })
                  openEnquiryForm({
                    courseName: hit.name,
                    providerName: hit.providerName,
                    providerSubtype: createTagList(hit?.providerSubtypeList),
                    formId: hit.id,
                    formComponent: FORM_COMPONENTS.COURSE_ENQUIRY,
                    loginType: SignUpType.Enquiry,
                    onClose: () => false,
                  })
                }}
              />
              <FinderResultItemSaveButton
                inventoryId={hit.id}
                saveInventoryItem={() =>
                  saveInventoryItem({
                    providerName: hit.name,
                    formId: hit.id,
                    loginType: SignUpType.Save,
                    formComponent: '',
                    onClose: () => false,
                    itemType: StudyOptionItemType.Course,
                  })
                }
              />
            </FinderResultItem.Buttons>
          </FinderResultItem>
        )
      })}
    </span>
  )
}

const CourseHits = connectHits<ComponentProps, CourseHit>(Component)

export default CourseHits
