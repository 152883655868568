import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@chakra-ui/react'
import { EnquiredIcon, EnquiryIcon, FavouriteIcon, SavedIcon } from 'icons'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Button, ButtonProps, StudyOptionStatus } from 'ui'
import { ROUTES } from '../../../constants'
import { useUser } from '../../../context'

export interface FinderResultEnquiryButtonProps extends ButtonProps {
  openEnquiryForm: () => void
  inventoryId: string
  isEnquired?: boolean
}

export interface FinderResultSaveButtonProps extends ButtonProps {
  saveInventoryItem: () => void
  inventoryId: string
}

export function FinderResultItemEnquiryButton({
  openEnquiryForm,
  inventoryId,
  isEnquired,
  ...props
}: FinderResultEnquiryButtonProps) {
  const { userState } = useUser()
  const { studyOptions } = userState
  const items = studyOptions?.items
  const item = items?.find((item) => item.itemId === inventoryId)
  const hasEnquired = isEnquired ? isEnquired : item?.status === StudyOptionStatus.Contacted

  const handleClick = () => {
    openEnquiryForm()
  }

  return (
    <Button
      variant={hasEnquired ? 'solid' : 'outline'}
      aria-label="enquire"
      onClick={handleClick}
      size="sm"
      rightIcon={<Box>{hasEnquired ? <EnquiredIcon /> : <EnquiryIcon width="22" />}</Box>}
      {...props}
    >
      {hasEnquired ? 'Enquiry sent. Follow up?' : 'Enquire'}
    </Button>
  )
}

export function FinderResultItemSaveButton({
  saveInventoryItem,
  inventoryId,
  ...props
}: FinderResultSaveButtonProps) {
  const router = useRouter()
  const { userState } = useUser()
  const { studyOptions } = userState
  const saved = StudyOptionStatus.Saved
  const contacted = StudyOptionStatus.Contacted
  const { isAuthenticated } = useAuth0()
  const [isSaved, setIsSaved] = useState(false)

  const isHidden = studyOptions?.items?.find(
    (item) => item.itemId === inventoryId && item.status === contacted
  )
    ? true
    : false

  useEffect(() => {
    setIsSaved(
      studyOptions?.items?.find((item) => item.itemId === inventoryId && item.status === saved)
        ? true
        : false
    )
  }, [inventoryId, saved, studyOptions])

  const handleOnClick = () => {
    if (isSaved) {
      router.push(ROUTES.FAVOURITES)
    } else {
      saveInventoryItem()
      if (isAuthenticated) {
        setIsSaved(true)
      }
    }
  }

  return (
    <>
      {isHidden ? (
        <></>
      ) : (
        <Button
          variant={isSaved ? 'solid' : 'outline'}
          aria-label={
            isSaved
              ? 'Saved to your favourites. Click to navigate to your favourites.'
              : 'Save to your favourites'
          }
          onClick={handleOnClick}
          size="sm"
          rightIcon={
            <Box
              boxSize={isSaved ? '' : '20px'}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {isSaved ? <SavedIcon /> : <FavouriteIcon height="100%" />}
            </Box>
          }
          sx={{
            '&:hover * svg *': {
              fill: 'white',
            },
          }}
          {...props}
        >
          {isSaved ? 'Saved to your favourites' : 'Save to your favourites'}
        </Button>
      )}
    </>
  )
}
