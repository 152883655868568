import { useAuth0 } from '@auth0/auth0-react'
import { Accordion, Box, GridItem, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { Fragment, useRef, useState } from 'react'
import { SearchState, connectRefinementList } from 'react-instantsearch-core'
import { TEALIUM_SEARCH_FILTER_MAPS } from 'tracking'
import {
  FacetRefinement,
  FilterAccordionItem,
  FilterButton,
  FilterDisplayLogic,
  FinderFilterPills,
  FinderResults,
  GridLayout,
  InstantSearchWrapper,
  PopularSearches,
  ResultFilters,
  ResultsFacetMenu,
  ResultsSearchBox,
  SortBy,
  StaticFacetRefinement,
  StudyOptionItemType,
  showRegionsAndCities,
  useHandleModalCloseResetData,
} from 'ui'
import { useEnquiryModalOnSignIn, useSavedModalOnSignIn } from 'ui/src/forms/helpers/enquiryForm'
import { setNZLocation, sortLevelOfSchools } from 'utils'
import { createConfig, createURL } from 'utils/src/helpers/algoliaSearch'
import useBrowseObjectsFetch, {
  BrowseObjectResult,
  getFacetAttributeValues,
} from 'utils/src/hooks/useBrowseObjectsFetch'
import useFacetValueFetch from 'utils/src/hooks/useFacetValueFetch'
import SavedSuccessModal, { SavedModalStatusType } from '../../../components/SavedSuccessModal'
import SignUpModal, { LoginConnectProps, SignUpType } from '../../../components/SignUpModal'
import { EnquiryFormConnectProps } from '../../../connectors'
import { ALGOLIA_FILTER_MAPS, ALGOLIA_INDICES, ROUTES } from '../../../constants'
import { popularProviders } from '../../../constants/popularSearchItems'
import { getProfile, useUser } from '../../../context'
import { sortByDescendingCount } from '../../../helpers'
import useUpdateInventoryItem from '../../../hooks/useUpdateInventoryItem'
import { ProviderHits } from '../SearchHit'

const PROVIDER_PROVIDER_SELECT_LIMIT = 700
const COUNTRY_FACET_LIMIT = 195 // There are 195 countries in the world
const COUNTRIES_ATTRIBUTE = 'countries.name'
interface ProviderFinderBrowseFetch {
  countries: {
    name: string
  }[]
  objectID: string
}

const { indexName, searchClient } = createConfig({
  indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN,
})

const providerFinderVirtualList = ['subtypeList.name', 'countries.name', 'regions.name', 'cities']

const VirtualRefinementList = connectRefinementList(() => null)
const VirtualRefinementLists = () => {
  return (
    <>
      {providerFinderVirtualList.map((attribute) => {
        return <VirtualRefinementList key={attribute} attribute={attribute} />
      })}
    </>
  )
}

ProviderFinderResults.FilterProvider = function ProviderFinderResultFilterProvider() {
  return (
    <Box>
      <ResultsFacetMenu
        heading="All providers"
        attribute="name"
        limit={PROVIDER_PROVIDER_SELECT_LIMIT}
      />
    </Box>
  )
}

ProviderFinderResults.SortBy = function ProviderFinderResultResultSortBy() {
  return (
    <Box>
      <SortBy
        defaultRefinement={ALGOLIA_INDICES.PROVIDER_FINDER.MAIN}
        items={[
          { value: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN, label: 'Relevant' },
          {
            value: ALGOLIA_INDICES.PROVIDER_FINDER.ASCENDING,
            label: 'A - Z',
          },
          {
            value: ALGOLIA_INDICES.PROVIDER_FINDER.DESCENDING,
            label: 'Z - A',
          },
        ]}
      />
    </Box>
  )
}

ProviderFinderResults.Results = function ProviderFinderResultsResults({
  nbHits,
  query,
  openEnquiryForm,
  saveInventoryItem,
  idsOfEnquiredInventoryItems,
}: {
  query: string
  nbHits: number
  openEnquiryForm: ({}: EnquiryFormConnectProps) => void
  saveInventoryItem: ({}: EnquiryFormConnectProps) => void
  idsOfEnquiredInventoryItems: string[]
}) {
  return (
    <FinderResults.Results>
      <FinderResults.ResultsBar>
        <ProviderFinderResults.FilterProvider />
        <ProviderFinderResults.SortBy />
      </FinderResults.ResultsBar>
      {nbHits ? (
        <FinderResults.ResultsContainer sideBarHeading="Education providers">
          <ProviderHits
            idsOfEnquiredInventoryItems={idsOfEnquiredInventoryItems}
            openEnquiryForm={openEnquiryForm}
            saveInventoryItem={saveInventoryItem}
          />
        </FinderResults.ResultsContainer>
      ) : (
        <FinderResults.NoResults query={query} />
      )}
      <FinderResults.Footer />
    </FinderResults.Results>
  )
}

ProviderFinderResults.Filters = function ProviderFinderResultsFilters({
  filterFacetValues,
}: {
  filterFacetValues: BrowseObjectResult<ProviderFinderBrowseFetch> | []
}) {
  const subtypeList = useFacetValueFetch({
    attribute: 'subtypeList.name',
    indexName,
  })

  const locations = getFacetAttributeValues(filterFacetValues, COUNTRIES_ATTRIBUTE).filter(
    (item) => item.value === 'New Zealand'
  )
  const regions = useFacetValueFetch({ attribute: 'regions.name', indexName })

  const subtypes = [...new Set([...sortLevelOfSchools(subtypeList), ...subtypeList])]

  return (
    <Accordion allowMultiple>
      {/* Provider subtype */}
      <FilterAccordionItem attribute="subtypeList.name" title="Education Provider Type">
        <StaticFacetRefinement staticItems={subtypes} attribute="subtypeList.name" />
      </FilterAccordionItem>
      {/* Countries Type */}
      <FilterAccordionItem attribute="countries.name" title="Location">
        <StaticFacetRefinement
          limit={COUNTRY_FACET_LIMIT}
          staticItems={locations}
          attribute={COUNTRIES_ATTRIBUTE}
          sort={sortByDescendingCount}
        />
      </FilterAccordionItem>
      {/* Region Type */}
      <FilterDisplayLogic attribute="countries.name" handleShowFilter={showRegionsAndCities}>
        <FilterAccordionItem attribute="regions.name" title="Region">
          <StaticFacetRefinement staticItems={regions} attribute="regions.name" />
        </FilterAccordionItem>
        {/* Cities Type */}
        <FilterAccordionItem attribute="cities" title="City">
          <FacetRefinement attribute="cities" />
        </FilterAccordionItem>
      </FilterDisplayLogic>
    </Accordion>
  )
}

//If the user selects a level of school, NZ will be automatically selected in location
const setNZSearchState = (searchState: SearchState) => {
  const levelsOfSchool = ['Primary School', 'Intermediate School', 'Secondary School']
  return setNZLocation(
    searchState,
    searchState?.refinementList?.['subtypeList.name'] &&
      searchState.refinementList['subtypeList.name']?.some((level) =>
        levelsOfSchool.includes(level)
      ) &&
      !searchState?.refinementList?.['countries.name']?.includes('New Zealand')
  )
}

const FormModal = dynamic(() => import('ui/src/forms/FormModal'))
const EnquiryFormConnect = dynamic(() => import('../../../connectors/EnquiryForm'))

function ProviderFinderResults() {
  const idsOfEnquiredInventoryItems = useRef<string[]>([])

  const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()

  const {
    isOpen: isSignUpOpen,
    onOpen: onSignUpModalOpen,
    onClose: onSignUpModalClose,
  } = useDisclosure()

  const {
    isOpen: isSaveModalOpen,
    onOpen: onSaveModalOpen,
    onClose: onSaveModalClose,
  } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleSaveItem = useUpdateInventoryItem(onSaveModalOpen)

  const handleModalCloseResetData = useHandleModalCloseResetData()

  const { isAuthenticated, isLoading, getAccessTokenSilently, user } = useAuth0()
  const { userDispatch } = useUser()

  const [currentLoginState, setCurrentLoginState] = useState<LoginConnectProps>({
    openModal: false,
  })

  const [savedStatus, setSavedStatus] = useState(SavedModalStatusType.Default)

  const [actionData, setActionData] = useState<EnquiryFormConnectProps | null>(null)
  const filterFacetValues = useBrowseObjectsFetch<ProviderFinderBrowseFetch>({
    attributes: [COUNTRIES_ATTRIBUTE],
    indexName,
  })

  useEnquiryModalOnSignIn(onModalOpen, setActionData)
  useSavedModalOnSignIn(onSignUpModalOpen, setSavedStatus)

  const handleEnquiryClick = async (props: EnquiryFormConnectProps) => {
    if (!isLoading) {
      setActionData(props)
      if (isAuthenticated) {
        const accessToken = await getAccessTokenSilently()
        await getProfile(userDispatch, user, accessToken)
        onModalOpen()
      } else {
        onSignUpModalOpen()
        setCurrentLoginState({
          loginType: SignUpType.Enquiry,
          openModal: true,
          inventory: props,
        })
      }
    }
  }

  const handleSaveClick = async (props: EnquiryFormConnectProps) => {
    if (!isLoading) {
      setActionData(props)
      if (isAuthenticated) {
        handleSaveItem(props.formId, StudyOptionItemType.Provider)
          .then(() => {
            setSavedStatus(SavedModalStatusType.Success)
          })
          .catch((err) => {
            setSavedStatus(SavedModalStatusType.Error)
          })
      } else {
        onSignUpModalOpen()
        setCurrentLoginState({
          loginType: SignUpType.Save,
          openModal: true,
          inventory: props,
        })
      }
    }
  }

  return (
    <Fragment>
      <InstantSearchWrapper
        indexName={indexName}
        searchClient={searchClient}
        searchState={{}}
        createURL={createURL}
        mapTealiumTrackingFields={TEALIUM_SEARCH_FILTER_MAPS.PROVIDER_FINDER_RESULTS}
        urlFilterMap={ALGOLIA_FILTER_MAPS.PROVIDER_FINDER_RESULTS}
      >
        <FinderResults>
          <FinderResults.Header title="Find an education provider">
            <VirtualRefinementLists />
            <GridLayout>
              <GridItem colSpan={{ base: 12, lg: 7 }}>
                <ResultsSearchBox
                  title="Search for an education provider"
                  placeholder="Search by name or keyword"
                  searchCoursesCtaProps={{
                    href: ROUTES.COURSE.RESULTS,
                    topic: 'courses',
                  }}
                />
              </GridItem>
              <GridItem colSpan={{ base: 12, lg: 5 }}>
                <PopularSearches
                  items={popularProviders}
                  popularSearchTitle="Education provider types"
                />
              </GridItem>
            </GridLayout>
            <ResultFilters
              searchClient={searchClient}
              indexName={indexName}
              isOpen={isOpen}
              onClose={onClose}
              editStateOnChange={setNZSearchState}
            >
              <ProviderFinderResults.Filters filterFacetValues={filterFacetValues} />
            </ResultFilters>
            <FinderResults.FilterContainer>
              {/** @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinement function. **/}
              <FilterButton onOpen={onOpen} ignoreRefinements={['name', 'type.name']} />
              {/** @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinement function. **/}
              <FinderFilterPills ignoreRefinements={['name', 'type.name']} />
            </FinderResults.FilterContainer>
          </FinderResults.Header>
          <FinderResults.Body>
            {({ searchResults }) => {
              const { nbHits, query } = searchResults || {}

              return (
                <ProviderFinderResults.Results
                  openEnquiryForm={handleEnquiryClick}
                  saveInventoryItem={handleSaveClick}
                  nbHits={nbHits}
                  query={query}
                  idsOfEnquiredInventoryItems={idsOfEnquiredInventoryItems.current}
                />
              )
            }}
          </FinderResults.Body>
        </FinderResults>
      </InstantSearchWrapper>
      {isModalOpen && actionData && (
        <FormModal isOpen={isModalOpen} onClose={() => handleModalCloseResetData(onModalClose)}>
          <EnquiryFormConnect
            {...actionData}
            onClose={() => handleModalCloseResetData(onModalClose)}
            onSuccess={() => {
              idsOfEnquiredInventoryItems.current = [
                ...idsOfEnquiredInventoryItems.current,
                actionData.formId,
              ]
            }}
          />
        </FormModal>
      )}
      {!isAuthenticated && (
        <SignUpModal
          loginState={currentLoginState}
          isOpen={isSignUpOpen}
          onClose={onSignUpModalClose}
        />
      )}
      <SavedSuccessModal
        loginState={currentLoginState}
        isOpen={isSaveModalOpen}
        onClose={() => handleModalCloseResetData(onSaveModalClose)}
        itemType="providers"
        status={savedStatus}
      />
    </Fragment>
  )
}

export default ProviderFinderResults
