import { connectHits, HitsProvided } from 'react-instantsearch-core'
import { createTagList, createUtagStartEnquiryEvent, INVENTORY_LABELS } from 'tracking'
import {
  DetailItem,
  FinderResultIntroLength,
  FinderResultItem,
  FORM_COMPONENTS,
  StudyOptionItemType,
} from 'ui'
import { searchStateToURL } from 'utils/src/helpers/algoliaSearch'
import { SignUpType } from '../../../components/SignUpModal'
import { EnquiryFormConnectProps } from '../../../connectors'
import { ROUTES } from '../../../constants'
import {
  FinderResultItemEnquiryButton,
  FinderResultItemSaveButton,
} from '../FinderResultItemButtons'

interface TaxonomyField {
  name: string
  tag: string
}

interface ProviderHit {
  cities: string[]
  countries: TaxonomyField[]
  defaultCampus: string
  id: string
  logoUrl: string
  name: string
  objectID: string
  description: string
  regions: TaxonomyField[]
  regionsWithCountriesAndCities: string[]
  subtype: TaxonomyField
  type: TaxonomyField
  url: string
  subtypeList: TaxonomyField[]
}

type ComponentProps = HitsProvided<ProviderHit> & {
  openEnquiryForm: ({
    courseName,
    providerName,
    providerSubtype,
    loginType,
  }: EnquiryFormConnectProps) => void
  saveInventoryItem: ({
    courseName,
    providerName,
    loginType,
  }: EnquiryFormConnectProps & {
    itemType: StudyOptionItemType.Provider
  }) => void
  idsOfEnquiredInventoryItems: string[]
}

const Component = ({
  hits,
  openEnquiryForm,
  saveInventoryItem,
  idsOfEnquiredInventoryItems,
}: ComponentProps) => {
  const createDetailItems = (hit: ProviderHit): DetailItem[] | [] => {
    const { regions = [] } = hit
    const regionList = regions.map((region) => region.name).join(', ')
    return [...(regions.length ? [{ label: 'Regions', value: regionList }] : [])]
  }

  const createCoursesLink = (provider: string) => {
    return searchStateToURL(ROUTES.COURSE.RESULTS, {
      page: 1,
      menu: {
        providerName: provider,
      },
    })
  }

  return (
    <span>
      {hits.map((hit: ProviderHit) => {
        const detailItems = createDetailItems(hit)
        return (
          <FinderResultItem key={hit.id}>
            {hit.logoUrl && <FinderResultItem.Logo provider={hit.name} logoUrl={hit.logoUrl} />}
            <FinderResultItem.MainContent>
              <FinderResultItem.Title
                title={hit.name}
                subTitle={hit.subtypeList
                  .map((subtype) => {
                    return subtype.name
                  })
                  .join(', ')}
                link={`${ROUTES.PROVIDER.DETAIL}/${hit.id}`}
              />
              <FinderResultItem.Description
                intro={hit.description}
                introLength={FinderResultIntroLength.LONG}
              />
              {detailItems && detailItems.length > 0 && (
                <FinderResultItem.DetailItems detailItems={detailItems} />
              )}
            </FinderResultItem.MainContent>
            <FinderResultItem.Buttons wrap={true}>
              <FinderResultItem.DetailsButton
                link={`${ROUTES.PROVIDER.DETAIL}/${hit.id}`}
                detailCta={{
                  label: 'See details',
                }}
              />
              {hit.type.tag !== 'nz-school' && (
                <FinderResultItem.OptionalButton
                  optionalLink={{
                    href: createCoursesLink(hit.name),
                    label: 'See courses',
                  }}
                />
              )}
              {hit?.type?.tag !== 'nz-scholarship-only-provider' && (
                <FinderResultItemEnquiryButton
                  isEnquired={idsOfEnquiredInventoryItems.includes(hit.id)}
                  inventoryId={hit.id}
                  openEnquiryForm={() => {
                    createUtagStartEnquiryEvent({
                      pageName: INVENTORY_LABELS.PROVIDER.PAGE_NAME,
                      inventoryId: hit?.id ?? '',
                      inventoryObjectType: INVENTORY_LABELS.PROVIDER.INVENTORY_OBJECT_TYPE,
                      levelOfStudy: '',
                      areaOfStudyArray: [],
                      subjectOfStudyArray: [],
                      deliveryModeArray: [],
                      courseName: '',
                      providerName: hit?.name ?? '',
                      providerType: hit?.type?.tag ?? '',
                      providerSubtype: createTagList(hit?.subtypeList),
                      campusRegionArray: createTagList(hit?.regions),
                      linkLabel: 'Enquire',
                    })
                    openEnquiryForm({
                      providerName: hit.name,
                      providerSubtype: createTagList(hit?.subtypeList),
                      formId: hit.id,
                      formComponent:
                        hit.type.name === 'NZ School'
                          ? FORM_COMPONENTS.SCHOOL_ENQUIRY
                          : FORM_COMPONENTS.PROVIDER_ENQUIRY,
                      loginType: SignUpType.Enquiry,
                      onClose: () => false,
                    })
                  }}
                />
              )}
              <FinderResultItemSaveButton
                inventoryId={hit.id}
                saveInventoryItem={() =>
                  saveInventoryItem({
                    providerName: hit.name,
                    formId: hit.id,
                    formComponent: '',
                    loginType: SignUpType.Save,
                    onClose: () => false,
                    itemType: StudyOptionItemType.Provider,
                  })
                }
              />
            </FinderResultItem.Buttons>
          </FinderResultItem>
        )
      })}
    </span>
  )
}

const InstitutionHit = connectHits<ComponentProps, ProviderHit>(Component)

export default InstitutionHit
