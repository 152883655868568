import { InventoryScholarship } from 'content-service'
import { format, isMatch } from 'date-fns'
import { createTagList } from 'tracking'

const formatApplicationDeadlineDate = (applicationDeadlines: string[] | undefined): string => {
  let formattedDeadlineDate = ''

  if (!applicationDeadlines || !applicationDeadlines.length) {
    return formattedDeadlineDate
  }

  const [deadlineDate] = applicationDeadlines
  if (typeof deadlineDate === 'string' && isMatch(deadlineDate, 'yyyy-MM-dd')) {
    formattedDeadlineDate = format(new Date(deadlineDate), 'do MMMM')
  }

  return formattedDeadlineDate
}

const validateScholarshipDetailProps = (scholarshipDetail: InventoryScholarship) => {
  const validScholarshipDetailProps = {
    id: scholarshipDetail.id || '',
    title: scholarshipDetail.title || '',
    providerLogoUrl: scholarshipDetail.providerLogoUrl || '',
    providerId: scholarshipDetail.providerId || '',
    scholarshipUrl: scholarshipDetail.url || '',
    eligibilityCriteriaHtmlString: scholarshipDetail.eligibilityCriteria || '',
    applicationInstructionsHtmlString: scholarshipDetail.applicationInstructions || '',
    providerName: scholarshipDetail?.providerName || '',
    providerType: scholarshipDetail?.providerType?.tag || '',
    providerSubtype: scholarshipDetail?.providerSubtype?.tag || '',
    providerSubtypeList: createTagList(scholarshipDetail?.providerSubtypeList),
    numberOfAwards: scholarshipDetail?.numberOfAwards ?? '',
    displayValue: scholarshipDetail?.displayValue ?? '',
    applicationDeadline: formatApplicationDeadlineDate(
      scholarshipDetail.applicationDeadlines as string[] | undefined
    ),
  }

  return validScholarshipDetailProps
}

export { validateScholarshipDetailProps, formatApplicationDeadlineDate }
